<template>
  <div class="container mescroll-touch">
    <div class="top">
      <img class="menuIcon" src="@/assets/images/menu_comm_icon@2x.png" @click="sidebarShow = true" />
      <div class="searchBox" @click.stop="$router.push('/search')">
        <span class="searchInput">请输入关键字搜索</span>
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
      </div>
    </div>
    <div class="header_title">
      <div class="titleItem">
        <span @click="$router.push('/activityZone')">活动专区</span>
      </div>
      <div class="titleItem titleItem_active">
        <span>资源解读</span>
      </div>
    </div>
    <div class="content">
      <div class="classify_content" ref="wrapper">
        <div class="classify_wrapper">
          <span :class="{'active_classify': tcode == item.TCcode}" v-for="(item, index) in DepartmentColumns" :key="index" @click="selectDepartment(item.TCcode)">{{item.Name}}</span>
        </div>
      </div>
      <div class="ListBox">
        <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
          <div class="activityList" id="dataList">
            <waterfall :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
              <template>
                <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
                  <div class="item_img">
                    <img class="item_cover" :src="item.Thumbnail" />
                    <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
                  </div>
                  <div class="item_content">
                    <span class="item_title">{{item.Title}}</span>
                    <p class="item_time">{{item.yyyymmdd}}</p>
                    <div class="item_nums">
                      <div class="nums">
                        <img src="@/assets/images/browse_comm_icon@2x.png" />
                        <span>{{item.WatchCount}}</span>
                      </div>
                      <div class="nums">
                        <img src="@/assets/images/like_comm_icon@2x.png" />
                        <span>{{item.LikeCount}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </waterfall>
          </div>
        </mescroll-vue>
      </div>
    </div>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import BScroll from 'better-scroll'
import Sidebar from '../../components/sidebar.vue'
import MescrollVue from 'mescroll.js/mescroll.vue'

export default {
  name: 'ResourceInterpret',
  data() {
    return {
      code: 'zyjd',
      sidebarShow: false,
      Keyword: '',
      DepartmentColumns: [],
      tcode: '',
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			}
    }
  },
  components: {
    Sidebar,
    MescrollVue
  },
  computed: {
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  created() {
    this.getDepartment();
  },
  methods: {
    // 初始化BScroll
    initBScroll() {
      this.$nextTick(() => {
        this.scroll = new BScroll(this.$refs['wrapper'], {
          startX: 0,
          click: true,
          scrollX: true,
          scrollY: false,
          eventPassthrough: 'vertical'
        })
      })
    },
    getDepartment() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=5&pcode=' + this.code).then(res => {
        this.DepartmentColumns = res.Data;
        this.tcode = res.Data[0].TCcode;
        this.initBScroll();
        this.list = [];
        this.mescroll.resetUpScroll();
      })
    },
    selectDepartment(tcode) {
      this.tcode = tcode;
      this.list = [];
      this.mescroll.resetUpScroll();
    },
    
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.tcode,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    popupClose(val) {
      this.sidebarShow = val;
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 0 0;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F9F9F9;
  .top {
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin: 0 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .header_title{
    display: flex;
    align-items: center;
    .titleItem {
      position: relative;
      flex: 1;
      height: 40px;
      line-height: 40px;
      position: relative;
      padding: 0 10px;
      font-size: 15px;
      color: #FFFFFF;
      text-align: center;
      // span {
      //   display: inline-block;
      //   width: 100%;
      // }
    }
    .titleItem_active::after {
      content: "";
      position: absolute;
      left: 40%;
      width: 20%;
      height: 3px;
      border-radius: 3px;
      background-color: #FFFFFF;
      bottom: 5px;
    }
  }
  .content {
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
    .classify_content {
      width: calc(100% - 24px);
      height: 31px;
      display: flex;
      font-size: 14px;
      overflow: hidden;
      margin: 0 12px 6px;
      .classify_wrapper {
        box-sizing: border-box;
        white-space: nowrap;
        span {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          height: 26px;
          line-height: 26px;
          border-radius: 26px;
          border: 1px solid #CCCCCC;
          font-size: 12px;
          color: #666666;
          padding: 0 12px;
          box-sizing: border-box;
        }
        span:not(:last-child) {
          margin-right: 8px;
        }
        .active_classify {
          color: #CA001B;
          border: 1px solid #CA001B;
        }
      }
    }
    .ListBox {
      flex: 1;
      height: 0;
    }
    .list {
      width: 100%;
      background-color: #FFFFFF;
      border-radius: 4px;
      overflow: hidden;
      margin: 0 12px 10px;
      // margin-bottom: 10px;
      .item_img {
        position: relative;
        .item_cover {
          display: block;
          width: 100%;
        }
        .playIcon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 30px;
          height: 30px;
        }
      }
      .item_content {
        padding: 5px 8px 8px;
        .item_title {
          height: 38px;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
        }
        .item_time {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
        }
        .item_label {
          display: flex;
          flex-wrap: wrap;
          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 6px;
            margin-bottom: 5px;
            background-color: rgba(255, 73, 98, 0.15);
            font-size: 11px;
            color: #CA001B;
          }
        }
        .item_nums {
          display: flex;
          justify-content: flex-end;
          .nums {
            display: flex;
            align-items: center;
            img {
              width: 12px;
              margin: 0 2px 0 6px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }
  }
}
</style>